import { render, staticRenderFns } from "./AmazonPay.vue?vue&type=template&id=2f7fa262&"
import script from "./AmazonPay.vue?vue&type=script&lang=js&"
export * from "./AmazonPay.vue?vue&type=script&lang=js&"
import style0 from "./AmazonPay.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "AmazonPay.vue"
export default component.exports